import { AnamneseConfirmada } from "./styles";
import React from "react";

export default function ConfirmacaoSend() {
  localStorage.clear();
  return (
    <AnamneseConfirmada>
      <h1>Enviado com sucesso, obrigado.</h1>
    </AnamneseConfirmada>
  );
}
