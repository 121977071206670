import { Container, Flex, Input, Link } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { Atendimentos } from "../../components/Atendimentos";
import Header from "../../components/Header";
import Loader from "../../components/Loader";
import api from "../../services/api";

export default function Dashboard() {
  const id_cliente = localStorage.getItem("id_cliente");
  const [loading, setloading] = useState(false);
  const [atendimentosPorStatus, setatendimentosPorStatus] = useState([]);
  const [reload, setreload] = useState(false);
  const [dataSelecionada, setDataSelecionada] = useState(
    localStorage.getItem("dataSelecionada")
  );

  setTimeout(function () {
    setreload(!reload);
  }, 130000);

  useEffect(() => {
    setloading(true);
    localStorage.setItem("dataSelecionada", dataSelecionada);
    api
      .post("atendimentos/presenca/find/data", {
        data: dataSelecionada,
        id_cliente,
      })
      .then((res) => {
        setatendimentosPorStatus(res.data);
        setloading(false);
      })
      .catch((err) => {
        window.location.href = "/";
      });
  }, [reload, dataSelecionada]);

  return (
    <Container maxW="7xl" py={4}>
      <Header />
      <Loader display={loading} />
      <Flex justifyContent={"space-between"}>
        <Input
          value={dataSelecionada}
          type="date"
          onChange={(e) => setDataSelecionada(e.target.value)}
          bg="white"
          maxW={"30%"}
        />
        <Link href="/dashboard">Tela inicial</Link>
      </Flex>

      <Atendimentos
        info={atendimentosPorStatus}
        titleElement="Atendimentos por Status"
      />
    </Container>
  );
}
