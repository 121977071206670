/*eslint-disable eqeqeq*/
import { Box, Button, SimpleGrid, Text } from '@chakra-ui/react';

import React from "react";
import { themePerson } from '../../styles/theme';

interface Action {
  Atendimento: array;
  handleSetAtendimentoSelecionado: () => void;
  hr_presenca: string;
}

export function AtendimentoItem({
  Atendimento,
  handleSetAtendimentoSelecionado,
  hr_presenca,
}: Action) {
  return (
    <Box onClick={() => handleSetAtendimentoSelecionado(Atendimento)} bg="white" p={4} cursor={'pointer'}>
      <Text>{hr_presenca}</Text>
      <Text>{Atendimento.nm_paciente}</Text>
      <Text fontWeight={'bold'}>{Atendimento.cd_atendimento}</Text>

      <SimpleGrid columns={[1, 1, 2]} spacing={1} marginTop={4}>
        {Atendimento.ds_leito ?  <Button bg={themePerson.primaryColor} color={'white'}> {Atendimento.ds_leito }</Button> : '' }
        <Button bg={Atendimento.sn_laudado == "S" ? 'green.100' : 'red.100' } > {Atendimento.sn_laudado == "S" ? 'Laudado' : 'Sem laudo' }</Button>
        <Button bg={Atendimento.sn_respondido == "S" ? 'green.100' : 'red.100' } > {Atendimento.sn_laudado == "S" ? 'Anamnese' : 'Anamnese' }</Button>
      </SimpleGrid>
    </Box>
  );
}
