/*eslint-disable eqeqeq*/
import { Container, Flex, Input, Select } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";

import { Atendimentos } from "../../components/Atendimentos";
import Header from "../../components/Header";
import Loader from "../../components/Loader";
import { ValidaPerfilEquipe } from "../../Functions/ValidaPerfilEquipe";
import api from "../../services/api";

export default function Dashboard() {
  const id_cliente = localStorage.getItem("id_cliente");
  const [loading, setloading] = useState(false);
  const [atendimentosPorStatus, setatendimentosPorStatus] = useState([]);
  const [sn_respondido, setsn_respondido] = useState(
    localStorage.getItem("status_sn_respondido")
  );
  const [reload, setreload] = useState(false);
  const [dataSelecionada, setDataSelecionada] = useState(
    localStorage.getItem("dataSelecionada")
  );

  setTimeout(function () {
    setreload(!reload);
  }, 130000);

  useEffect(() => {
    localStorage.setItem("dataSelecionada", dataSelecionada);
    localStorage.setItem("status_sn_respondido", sn_respondido);
    setloading(true);

    api
      .post("atendimentos/find/data", {
        data: dataSelecionada,
        sn_respondido,
        id_cliente,
      })
      .then((res) => {
        setatendimentosPorStatus(res.data);
        setloading(false);
      })
      .catch(() => {
        window.location.href = "/";
      });
  }, [reload, dataSelecionada, sn_respondido]);

  return (
    <Container maxW="7xl" py={8}>
      <Header />
      <Loader display={loading} />

      <Flex spacing={8}>
        <Input
          value={dataSelecionada}
          type="date"
          onChange={(e) => setDataSelecionada(e.target.value)}
          bg={"white"}
          maxW={"30%"}
        />

        <Select
          defaultValue={"DEFAULT"}
          onChange={(e) => setsn_respondido(e.target.value)}
          placeholder="Selecione um status"
          bg={"white"}
        >
          <option value="S">Questionários respondidos</option>
          <option value="N">Pacientes que não responderam</option>
        </Select>
      </Flex>

      {ValidaPerfilEquipe() == true ? (
        <a href="/presencas" id="link-ref">
          Visualizar Presenças
        </a>
      ) : (
        ""
      )}

      <Atendimentos
        info={atendimentosPorStatus}
        titleElement="Atendimentos por Status"
      />
    </Container>
  );
}
