import React from "react";

interface Action {
  title: string;
  handleSetVal: () => void;
}

function AnamneseOpcoesChance({ title, handleSetVal }: Action) {
  return (
    <div id="selects">
      <lable>{title}</lable>
      <select
        className="input-secundario"
        onChange={(e) => handleSetVal(e.target.value)}
      >
        <option value="DEFAULT" selected disabled>
          Selecione uma opção
        </option>
        <option value="Nenhuma Chance">0 -Nenhuma Chance</option>
        <option value="Pequena Chance">1 -Pequena Chance</option>
        <option value="Moderada Chance">2 -Moderada Chance</option>
        <option value="Alta Chance">3 -Alta Chance</option>
      </select>
    </div>
  );
}

export default AnamneseOpcoesChance;
