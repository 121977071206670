import React, { useState } from "react";

import { AtendimentoItem } from "../AtendimentoItem";
import { AtendimentoSelecionadoModal } from "../AtendimentoSelecionadoModal";
import { SimpleGrid } from '@chakra-ui/react';

interface Action {
  info: array;
  titleElement: string;
}

export function Atendimentos({ info }: Action) {
  const [
    modalIsOpenAtendimentoSelecionado,
    setmodalIsOpenAtendimentoSelecionado,
  ] = useState(false);

  const [atendimentoSelecionado, setatendimentoSelecionado] = useState([]);

  const handleSetAtendimentoSelecionado = (data) => {
    if (data) {
      setatendimentoSelecionado(data);
    }
    setmodalIsOpenAtendimentoSelecionado(!modalIsOpenAtendimentoSelecionado);
  };

  return (
    <SimpleGrid columns={[1, 1, 5]} spacing={8} marginTop={4}>
      {info.map((atendimento, index) => (
        <AtendimentoItem
          key={index}
          Atendimento={atendimento}
          handleSetAtendimentoSelecionado={handleSetAtendimentoSelecionado}
          hr_presenca={atendimento.hr_presenca}
        />
      ))}

      <AtendimentoSelecionadoModal
        isOpen={modalIsOpenAtendimentoSelecionado}
        handleChangeModalIsOpen={handleSetAtendimentoSelecionado}
        info={atendimentoSelecionado}
      />
    </SimpleGrid>
  );
}
