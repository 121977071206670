import React, { Fragment, useState } from "react";

import { ButtonPrimary } from "../../styles/Buttons/Primary";
import { DivLogin } from "./styles";
import { StyleInput } from "../../styles/input";
import api from "../../services/api";
import { toast } from "react-toastify";

/*eslint-disable eqeqeq*/

export default function Login() {
  const [usuario, setUsuario] = useState("");
  const [senha, setSenha] = useState("");

  async function handleLogin(e) {
    e.preventDefault();

    api
      .post("usuarios/login", { usuario, senha })
      .then((res) => {
        if (res.data.user) {
          localStorage.setItem("token", "Bearer " + res.data.token);
          localStorage.setItem("id_acesso", res.data.user[0].id_acesso);
          localStorage.setItem("id_usuario", res.data.user[0].id);
          localStorage.setItem("id_cliente", res.data.user[0].id_cliente);
          localStorage.setItem("tp_acesso", res.data.user[0].tp_acesso);
          localStorage.setItem("usuario", res.data.user[0].usuario);
          window.location.href = "/dashboard";
        }
      })
      .catch(() => {
        toast.error("Falha na Autenticação");
      });
  }

  const handleSetUsuario = (data) => {
    setUsuario(data);
  };

  const handleSetSenha = (data) => {
    setSenha(data);
  };

  return (
    <Fragment>
      <DivLogin>
        <form onSubmit={handleLogin}>
          <h1>Fazer login no sistema</h1>

          <StyleInput
            title={"Usuário"}
            type={"text"}
            action={handleSetUsuario}
          />
          <StyleInput
            title={"Usuário"}
            type={"password"}
            action={handleSetSenha}
          />

          <ButtonPrimary title="Entrar" type="submit" />
        </form>
      </DivLogin>
    </Fragment>
  );
}
