import styled from "styled-components";

export const Content = styled.div`
  margin-top: 2rem;
  display: flex;
  width: 100%;
  justify-content: space-around;

  button {
    color: var(--dark-primary-color-1);
    height: 3rem;
    border: none;
    background-color: var(--white);
    transition: filter 0.2s;
    border-radius: 4px;
    padding: 8px;
    font-size: 0.8rem;
    transition: filter 0.2s;
    box-shadow: 0px 2px 2px 2px rgba(0, 0, 0, 0.15);

    &:hover {
      filter: brightness(0.9);
    }
  }
`;

export const Container = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  h1 {
    color: var(--white);
  }
`;

export const LinkModal = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
`;
