import styled from "styled-components";

export const Container = styled.div`
  margin: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  text-align: center;

  h2 {
    margin-bottom: 32px !important;
  }

  a {
    margin-top: 5rem !important;
    padding: 32px !important;
    border-radius: 4px !important;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    min-width: 40% !important;
    color: var(--white) !important;

    &:hover {
      cursor: pointer;
    }
  }
`;

export const LinkGreen = styled.a`
  background-color: var(--green);
`;
export const LinkRed = styled.a`
  background-color: var(--red);
`;

export const FontRed = styled.p`
  color: var(--red);
`;
