import styled from "styled-components";

export const FormAnamnese = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  input {
    display: flex;
    width: 100%;
  }
  h2 {
    margin-top: 4rem !important;
    margin-bottom: 1rem;
  }
  select {
    display: flex;
    width: 100%;
  }
  button {
    min-width: 20% !important;
    height: 5rem;
    border: none;
    border-radius: 4px;
    background-color: var(--primary-color0);
    color: var(--white);

    transition: filter 0.2s;

    &:hover {
      filter: brightness(0.9);
    }
  }
`;

export const AnamneseConfirmada = styled.div`
  margin: 0 auto;
  display: flex;
  justify-content: center;
  place-content: center;
  align-items: center;
  height: 100vh;
  text-align: center;
`;
