/* eslint-disable*/

export function ValidaPerfilLaudador() {
  const id_acesso = localStorage.getItem("id_acesso");
  const id_laudador = "a63301c2-6774-4dab-b0fe-1e5ed7c5e6b3";

  if (id_acesso == id_laudador) {
    return true;
  }

  return false;
}
