import axios from "axios";

const token = localStorage.getItem("token") || "";
const id_cliente = localStorage.getItem("id_cliente") || "";
const api = axios.create({
  baseURL: "https://polissonografia-backend-production.up.railway.app",
  //baseURL: "http://localhost:3333",
  headers: {
    Authorization: token,
    id_cliente: id_cliente,
    "Content-Type": "application/json",
    Accept: "application/json",
    "Access-Control-Allow-Origin": "*",
  },
});

export default api;
