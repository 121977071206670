import styled from "styled-components";

export const FormObservacao = styled.form`
  display: flex;
  flex-direction: row;
  input {
    height: 3rem;
    min-width: 30%;
    border-radius: 4px;
    border: none;
  }
  button {
    border: none;
    height: 3rem;
    background-color: var(--green);
    color: var(--white);
    padding: 16px;
    border-radius: 4px;
  }
`;

export const InformacoesAtendimento = styled.div`
  margin-top: 1rem;
  display: grid;
  grid-gap: 16px;
`;

export const InformacoesPaciente = styled.div`
  margin-top: 1rem;
  margin-bottom: 1rem;
  display: grid;
  grid-gap: 16px;
`;

export const InformacoesAnamnese = styled.div`
  margin-top: 1rem;
  margin-bottom: 1rem;
  display: grid;
  grid-gap: 16px;
`;

export const LinkModal = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
`;

export const ButtonsAction = styled.div`
  display: flex;
  margin-top: 2rem;
  margin-bottom: 2rem;
  grid-gap: 8px;

  button {
    min-width: 20% !important;
    height: 3rem;
    border: none;
    border-radius: 4px;
    background-color: var(--primary-color0);
    color: var(--white);

    transition: filter 0.2s;

    &:hover {
      filter: brightness(0.9);
    }
  }
`;
