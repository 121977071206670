import React from "react";

interface Action {
  title: string;
  orientacoes: string;
  handleSetVal: () => void;
}

function InputAnamnese({ title, orientacoes, handleSetVal }: Action) {
  return (
    <div id="selects">
      <lable>{title}</lable>
      <input
        type="text"
        className="input-secundario"
        required=""
        placeholder={orientacoes}
        onChange={(e) => handleSetVal(e.target.value)}
      />
    </div>
  );
}

export default InputAnamnese;
