import React from "react";

interface Action {
  title: string;
  handleSetVal: () => void;
}

function PeriodoInsonia({ title, handleSetVal }: Action) {
  return (
    <div id="selects">
      <lable>{title}</lable>
      <select
        className="input-secundario"
        onChange={(e) => handleSetVal(e.target.value)}
      >
        <option selected="">Selecione</option>
        <option value="Inicio da Noite">Inicio da Noite</option>
        <option value="Meio da Noite">Meio da Noite</option>
        <option value="Fim da Noite">Fim da Noite</option>
      </select>
    </div>
  );
}

export default PeriodoInsonia;
