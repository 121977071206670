import { BrowserRouter, Route, Switch } from "react-router-dom";

import Anamnese from "./pages/Anamnese";
import ConfirmacaoEnvioAnamnese from "./pages/Anamnese/Confirmacao";
import Dashboard from "./pages/dashboard";
import Login from "./pages/usuarios/Login";
import Logout from "./pages/Logout";
import Presencas from "./pages/Presencas";
import React from "react";

export default function Routes() {
  return (
    <BrowserRouter>
      <Switch>
        <Route path="/" exact component={Login} />
        <Route path="/dashboard" exact component={Dashboard} />
        <Route path="/presencas" exact component={Presencas} />
        <Route path="/logout" exact component={Logout} />
        <Route path="/sair" exact component={Logout} />
        <Route path="/send" exact component={ConfirmacaoEnvioAnamnese} />
        <Route
          path="/anamnese/:id/:id_cliente/:token"
          exact
          component={Anamnese}
        />
        <Route path="*" exact component={Login} />
      </Switch>
    </BrowserRouter>
  );
}
