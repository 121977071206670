const config = {
  useSystemColorMode: false,
  initialColorMode: 'dark',
};

export const themePerson = {
  primaryColor: '#172b44',
  primaryColorHover: '#24436a',
  fontColor: '#29292e',
  background: '#f4f7fb',
  styles: {
    global: {
      body: {
        backgroundColor: '#f4f7fb',
        color: '#29292e',
      },
      fonts: {
        body: 'Open Sans, sans-serif',
        color: '#29292e',
      },
      colors: {},
    },
    ...config,
  },
};
