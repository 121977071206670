/* eslint-disable*/

export function ValidaPerfilEquipe() {
  const id_acesso = localStorage.getItem("id_acesso");
  const id_equipe = "75fbeaa8-a68e-4d59-b93d-39dcdc02b312";

  if (id_acesso == id_equipe) {
    return true;
  }

  return false;
}
