/* eslint-disable*/

export function ValidaPerfilRecepcao() {
  const id_acesso = localStorage.getItem("id_acesso");
  const id_recepcao = "e4cda73c-dd08-4b17-aeb6-7164ca185374";

  if (id_acesso == id_recepcao) {
    return true;
  }

  return false;
}
