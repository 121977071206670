import styled from "styled-components";

export const Button = styled.button`
  color: var(--white);
  height: 3rem;
  border: none;
  background-color: var(--primary-color0);
  transition: filter 0.2s;
  border-radius: 4px;
  padding: 8px;
  font-size: 0.8rem;
  min-width: 100%;
  transition: filter 0.2s;
  box-shadow: 0px 2px 2px 2px rgba(0, 0, 0, 0.15);

  &:hover {
    filter: brightness(0.9);
  }
`;
