import { Button } from "./styles";
import React from "react";

interface Action {
  title: string;
  action: () => void;
  type: string;
}

export function ButtonPrimary({ title, action, type }: Action) {
  return (
    <Button onClick={action} type={type}>
      {title}
    </Button>
  );
}
