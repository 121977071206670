import axios from "axios";

const apiV2Polissono = axios.create({
  baseURL: "https://polissono-api-v2-4hprsyg2xa-uw.a.run.app",
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
    "Access-Control-Allow-Origin": "*",
    authorization: "ai$y96HsaNCL$5em5eFXT!PFMq",
  },
});

export { apiV2Polissono };
