import React, { Fragment } from "react";

interface Action {
  Atendimento: array;
}

export function AnamneseRespostas({ Atendimento }: Action) {
  if (Atendimento) {
    return (
      <Fragment>
        <hr />
        <h2>Anamnese </h2>
        <p>Peso: {Atendimento.vl_peso + " KG"}</p>
        <p>Altura: {Atendimento.vl_altura}</p>
        <h3>Sobre o ronco</h3>
        <p>1 - Você Ronca? {Atendimento.q1_ronco1}</p>
        <p>2 - Seu ronco é alto? {Atendimento.q1_ronco2}</p>
        <p>
          3 - A posição de dormir influência no ronco? {Atendimento.q1_ronco3}
        </p>
        <p>4 - Você tem obstrução nasal? {Atendimento.q1_ronco4}</p>
        <p>5 - Você tem coriza? {Atendimento.q1_ronco5}</p>

        <h3>Sobre apnéia</h3>
        <p>
          1 - Alguém já lhe disse que você para de respirar durante a noite?{" "}
          {Atendimento.q2_apneia1}
        </p>
        <p>2 - Você já acordou engasgado? {Atendimento.q2_apneia2}</p>
        <p>3 - Você já acordou com falta de ar?{Atendimento.q2_apneia3}</p>

        <h3>Sobre Sonolência</h3>
        <p>1 - Você já dormiu no cinema? {Atendimento.q3_sonolencia1}</p>
        <p>2 - Você cochila lendo jornal? {Atendimento.q3_sonolencia2}</p>
        <p>
          3 - Você fica extremamente sonolendo no trânsito?{" "}
          {Atendimento.q3_sonolencia3}
        </p>
        <p>4 - Você já dormiu dirigindo? {Atendimento.q3_sonolencia4}</p>
        <p>
          5 - Você já dormiu em reunião importante? {Atendimento.q3_sonolencia5}
        </p>

        <h3>Sobre o sono</h3>
        <p>
          1 - Quantas horas você dorme em média por noite?{" "}
          {Atendimento.q4_sono1}
        </p>
        <p>
          2 - Quanto tempo demora para dormir? (Minutos) {Atendimento.q4_sono2}
        </p>
        <p>3 - Você tem insônia? {Atendimento.q4_sono3}</p>
        <p>4 - Período de insônia? {Atendimento.q4_sono4}</p>
        <p>5 - Após o sono sente-se disposto? {Atendimento.q4_sono5}</p>
        <p>6 - Seu sono é agitado? {Atendimento.q4_sono6}</p>
        <p>
          7 - Ocorre movimentos involuntários abruptos durante a noite?{" "}
          {Atendimento.q4_sono7}
        </p>
        <p>8 - Você fala dormindo? {Atendimento.q4_sono8}</p>
        <p>9 - Você anda durante o sono? {Atendimento.q4_sono9}</p>
        <p>
          10 - Você range os dentes durante a noite? {Atendimento.q4_sono10}
        </p>
        <p>
          11 - Você perde a força muscular das pernas durante a noite?{" "}
          {Atendimento.q4_sono11}
        </p>
        <p>12 - Você tem desmaios durante a noite? {Atendimento.q4_sono12}</p>

        <h3>Queixas Associadas</h3>

        <p>1 - Você acorda com dor de cabeça? {Atendimento.q5_queixa1}</p>
        <p>2 - Você acorda para urinar? {Atendimento.q5_queixa2}</p>
        <p>3 - Você tem problemas de ordem sexual? {Atendimento.q5_queixa3}</p>
        <p>4 - Você tem depressão? {Atendimento.q5_queixa4}</p>
        <p>
          5 - Você apresentou ganho de peso nos últimos anos?{" "}
          {Atendimento.q5_queixa5}
        </p>
        <p>5.1 - Quanto? {Atendimento.q5_queixa5_1}</p>
        <p>
          6 - Qual o seu peso aproximado aos 20 anos? {Atendimento.q5_queixa6}
        </p>
        <p>7 - Ja fez tratamento para emagrecer? {Atendimento.q5_queixa7}</p>
        <p>8 - Faz uso de medicamentos atualmente? {Atendimento.q5_queixa8}</p>
        <p>8.1 - Quais? {Atendimento.q5_queixa8_1}</p>
        <p>9 - Cirurgias anteriores? Quais? {Atendimento.q5_queixa9}</p>

        <h3>Escala de sonolência diurna de epwoth</h3>
        <p>1 - Sentado e lendo {Atendimento.q6_escala1}</p>
        <p>2 - Vendo televisão {Atendimento.q6_escala2}</p>
        <p>3 - Sentado em local público {Atendimento.q6_escala3}</p>
        <p>
          4 - Como passageiro de carro, ônibus ou trem {Atendimento.q6_escala4}
        </p>
        <p>5 - Deitado para descansar à tarde {Atendimento.q6_escala5}</p>
        <p>6 - Sentado conversando com alguém {Atendimento.q6_escala6}</p>
        <p>7 - Sentado calmamente após o almoço {Atendimento.q6_escala7}</p>
        <p>
          8 - Se você estiver de carro, e trânsito intenso com o carro parado{" "}
          {Atendimento.q6_escala8}
        </p>

        <h3>Pré exame</h3>
        <p>1 - Indíce de ansiedade {Atendimento.q7_preexame1}</p>
        <p>2 - Dormiu bem a noite passada? {Atendimento.q7_preexame2}</p>
        <p>3 - índice de nervosismo {Atendimento.q7_preexame3}</p>
      </Fragment>
    );
  } else {
    return <div>Sem info</div>;
  }
}
