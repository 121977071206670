import styled from "styled-components";

export const Container = styled.input`
  text-align: left;
  background-color: var(--white);
  border: 1px solid #4885ed;
  box-sizing: border-box;
  border-radius: 4px;
  min-height: 60px;
  color: var(--gray);
  padding: 16px;
  min-width: 100%;
  margin-bottom: 8px;
  box-shadow: 0px 2px 2px 2px rgba(0, 0, 0, 0.15);

  &:focus {
    color: var(--gray);
    border: 2px solid var(--primary-color0);
    background-color: var(--white);
  }
`;
