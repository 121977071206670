/*eslint-disable eqeqeq*/
import {
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  SimpleGrid,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import {
  FiEye,
  FiFileText,
  FiMail,
  FiPenTool,
  FiSave,
  FiX,
} from "react-icons/fi";
import {
  FormObservacao,
  InformacoesAnamnese,
  InformacoesAtendimento,
  InformacoesPaciente,
  LinkModal,
} from "./styles";

import axios from "axios";
import { ValidaPerfilEquipe } from "../../Functions/ValidaPerfilEquipe";
import { ValidaPerfilLaudador } from "../../Functions/ValidaPerfilLaudador";
import { ValidaPerfilRecepcao } from "../../Functions/ValidaPerfilRecepcao";
import IconDocs1 from "../../assets/IconDocs1.svg";
import api from "../../services/api";
import { themePerson } from "../../styles/theme";
import { AnamneseRespostas } from "../AnamneseRespostas";
import { PresencaModal } from "../AtendimentosPresencaPaciente";
import { AtendimentoRefazerExames } from "../AtendimentosRefazerExame";
import { LaudarModal } from "../Laudar";
import Loader from "../Loader";
import { apiV2Polissono } from "../../services/apiPolissono";

interface Action {
  isOpen: boolean;
  handleChangeModalIsOpen: () => void;
  info: array;
}

export function AtendimentoSelecionadoModal({
  isOpen,
  handleChangeModalIsOpen,
  info,
}: Action) {
  const [ObservacaoNew, setObservacaoNew] = useState("");
  const [Leitos, setLeitos] = useState([]);
  const [AtendimentoByID, setAtendimentoByID] = useState([]);
  const [ConfirmacaoPresenca, setConfirmacaoPresenca] = useState([]);
  const [loading, setloading] = useState(true);
  const [Idade, setIdade] = useState(true);
  const [reload, setreload] = useState(true);
  const [pacientePresente, setpacientePresente] = useState(false);
  const [modalIsOpenLaudar, setmodalIsOpenLaudar] = useState(false);
  const [modalIsOpenRefazerExame, setmodalIsOpenRefazerExame] = useState(false);
  const ano_atual = new Date().getFullYear();
  const id_cliente = localStorage.getItem("id_cliente");

  const handleChangeModalLaudar = () => {
    setmodalIsOpenLaudar(!modalIsOpenLaudar);
  };

  const handleReload = () => {
    setreload(!reload);
  };

  const handleVisualizarLaudo = (data) => {
    window.open(data.url_aws_laudo, "_blank");
  };

  const handleClosePresencaPaciente = () => {
    setpacientePresente(false);
  };

  async function handleLinkAnamnese(data) {
    const token = await axios
      .post(
        "https://polissonografia-backend-production.up.railway.app/login/pacientes",
        { key: "XnJNIdcD6ocAvezS4b" }
      )
      .then((res) => {
        return res.data.token;
      })
      .catch(() => {
        alert(err);
      });

    const link = `https://polissonografia.com/anamnese/${data.id}/${id_cliente}/${token}`;
    window.open(link, "_blank");
  }

  async function handleObservacao(e) {
    e.preventDefault();
    api
      .post("atendimentos/update/observacao", {
        id_cliente,
        id: info.id,
        ds_observacao: ObservacaoNew,
      })
      .then(() => {
        handleReload();
      });
  }

  const OpenHelpCenter = () => {
    window.open(
      "https://www.notion.so/Polissonografia-ae077c18a45b431a8b5fa88f6f3308cd",
      "_blank"
    );
  };

  const handleSendEmailRefazer = (data) => {
    setmodalIsOpenRefazerExame(true);
  };

  const handleDocumentoAnexado = (data) => {
    setloading(true);
    apiV2Polissono
      .get("documents/" + data.cd_atendimento)
      .then((response) => {
        setloading(false);

        response.data.map((item) => {
          window.open(item.url, "_blank");
        });
      })
      .catch((err) => {
        console.log(err);
        setloading(false);
      });
  };

  useEffect(() => {
    if (isOpen) {
      setloading(true);
      api
        .post("atendimentos/find/id", {
          id_cliente: localStorage.getItem("id_cliente"),
          id: info.id,
        })
        .then((response) => {
          setAtendimentoByID(response.data[0]);
          setloading(false);
          setIdade(ano_atual - response.data[0].dt_nascimento.split("/")[2]);
        })
        .catch(() => {
          setloading(false);
        });
    }
  }, [isOpen, reload]);

  useEffect(() => {
    api
      .post("leitos", {
        id_cliente: localStorage.getItem("id_cliente"),
      })
      .then((res) => {
        setLeitos(res.data);
      });
    if (isOpen)
      api
        .post("atendimentos/presenca/find/id_atendimento", {
          id_cliente,
          id_atendimento: AtendimentoByID.id,
        })
        .then((res) => {
          setConfirmacaoPresenca(res.data[0]);
          if (ValidaPerfilRecepcao() === true) {
            res.data.length === 0
              ? setpacientePresente(true)
              : setpacientePresente(false);
          }
        });
  }, [AtendimentoByID.id]);

  function handleSetLeito(e) {
    api
      .post("atendimentos/update/leito", {
        id_cliente: localStorage.getItem("id_cliente"),
        id: AtendimentoByID.id,
        ds_leito: e,
      })
      .then(() => {
        window.location.reload();
      });
  }

  return (
    <>
      <LaudarModal
        isOpen={modalIsOpenLaudar}
        handleChangeModalIsOpen={handleChangeModalLaudar}
        handleReload={handleReload}
        info={AtendimentoByID}
        id_cliente={id_cliente}
      />

      <PresencaModal
        handleChangeModalIsOpen={handleClosePresencaPaciente}
        isOpen={pacientePresente}
        id_atendimento={AtendimentoByID.id}
        id_cliente={id_cliente}
        nm_paciente={AtendimentoByID.nm_paciente}
        id_paciente={AtendimentoByID.cd_paciente}
      />

      <AtendimentoRefazerExames
        isOpen={modalIsOpenRefazerExame}
        handleChangeModalIsOpen={() => setmodalIsOpenRefazerExame(false)}
        info={AtendimentoByID}
      />

      <Modal
        isOpen={isOpen}
        size="full"
        onRequestClose={handleChangeModalIsOpen}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader></ModalHeader>
          <ModalBody>
            <Loader display={loading} />
            <LinkModal>
              <FiX
                onClick={() => handleChangeModalIsOpen()}
                color="red"
                size="32"
              />
            </LinkModal>

            <img src={IconDocs1} width={150} alt="Icon" />
            <InformacoesPaciente>
              <h1>{AtendimentoByID.nm_paciente}</h1>
              <p>
                <b>Prontuário:</b> {AtendimentoByID.cd_paciente}
              </p>
              <p>
                <b>Idade: </b>
                {Idade}
                {" anos ("} {AtendimentoByID.dt_nascimento} {")"}
              </p>
              <p>
                <b>Observações: {AtendimentoByID.ds_observacao}</b>
              </p>
              {ValidaPerfilEquipe() === true ? (
                <FormObservacao onSubmit={handleObservacao}>
                  <input onChange={(e) => setObservacaoNew(e.target.value)} />
                  <button type="submit">Salvar Observação</button>
                </FormObservacao>
              ) : (
                ""
              )}
            </InformacoesPaciente>
            <SimpleGrid columns={[1, 1, 5]} spacing={2} marginTop={4}>
              {AtendimentoByID.ds_leito ? (
                <Button
                  bg={themePerson.primaryColorHover}
                  _hover={{ bg: themePerson.primaryColorHover }}
                  color={"white"}
                >
                  {AtendimentoByID.ds_leito}
                </Button>
              ) : (
                ""
              )}

              {ValidaPerfilLaudador() ? (
                <Button
                  onClick={() => handleChangeModalLaudar()}
                  rightIcon={<FiSave size="16" />}
                  bg={themePerson.primaryColor}
                  _hover={{ bg: themePerson.primaryColorHover }}
                  color={"white"}
                >
                  Laudar
                </Button>
              ) : (
                ""
              )}
              <Button
                onClick={() => handleLinkAnamnese(AtendimentoByID)}
                rightIcon={<FiPenTool size="16" />}
                bg={themePerson.primaryColor}
                _hover={{ bg: themePerson.primaryColorHover }}
                color={"white"}
              >
                Link Anamnese
              </Button>
              <Button
                onClick={() => handleDocumentoAnexado(AtendimentoByID)}
                rightIcon={<FiEye size="16" />}
                bg={themePerson.primaryColor}
                _hover={{ bg: themePerson.primaryColorHover }}
                color={"white"}
              >
                Solicitação de Exame
              </Button>
              {AtendimentoByID.sn_laudado == "S" ? (
                <Button
                  onClick={() => handleVisualizarLaudo(AtendimentoByID)}
                  rightIcon={<FiFileText size="16" />}
                  bg={themePerson.primaryColor}
                  _hover={{ bg: themePerson.primaryColorHover }}
                  color={"white"}
                >
                  Visualizar Laudo
                </Button>
              ) : (
                ""
              )}
              <Button
                onClick={() => handleSendEmailRefazer(AtendimentoByID)}
                rightIcon={<FiMail size="16" />}
                bg={themePerson.primaryColor}
                _hover={{ bg: themePerson.primaryColorHover }}
                color={"white"}
              >
                Email refazer exame
              </Button>

              {!ConfirmacaoPresenca ? (
                <Button
                  onClick={() => handleVisualizarLaudo(AtendimentoByID)}
                  rightIcon={<FiFileText size="16" />}
                  bg={themePerson.primaryColor}
                  _hover={{ bg: themePerson.primaryColorHover }}
                  color={"white"}
                >
                  Presença Pendente
                </Button>
              ) : (
                ""
              )}
              <Select
                placeholder="Selecione um leito para alterar."
                onChange={(e) => handleSetLeito(e.target.value)}
              >
                {Leitos.map((leito) => (
                  <option value={leito.ds_leito}>{leito.ds_leito}</option>
                ))}
              </Select>
            </SimpleGrid>

            <InformacoesAtendimento>
              <p>
                <b>Solicitante:</b> {AtendimentoByID.nm_medico_solicitante}
              </p>
              <p>
                <b>Atendimento:</b> {AtendimentoByID.cd_atendimento}
              </p>
              <p>
                <b>Pedido:</b> {AtendimentoByID.cd_pedido}
              </p>
              <p>
                <b>ID:</b> {AtendimentoByID.id}
              </p>
              <p>
                <b>Exame:</b> {AtendimentoByID.nm_exame}
              </p>
            </InformacoesAtendimento>
            <InformacoesAnamnese>
              <AnamneseRespostas Atendimento={AtendimentoByID} />
            </InformacoesAnamnese>
            <small>
              Paciente atendido por {AtendimentoByID.nm_usuario_atendimento}{" "}
            </small>
          </ModalBody>
          <ModalFooter></ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
