import { Container, Link, Links } from "./styles";

import React from "react";
import { FiHelpCircle } from "react-icons/fi";

function Header() {
  const OpenHelpCenter = () => {
    window.open(
      "https://femoraes.atlassian.net/servicedesk/customer/portal/7",
      "_blank"
    );
  };

  return (
    <Container>
      <Links>
        <Link onClick={() => OpenHelpCenter()}>
          <FiHelpCircle size="32" />{" "}
        </Link>
      </Links>
    </Container>
  );
}

export default React.memo(Header);
