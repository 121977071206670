import React, { Fragment, useEffect, useState } from "react";

import api from "../../services/api";

interface Action {
  handleSetVal: () => void;
  id_cliente: string;
}

export function AnamneseOpcoesIdentificacaoExame({
  handleSetVal,
  id_cliente,
}: Action) {
  const [identificacoes, setIdentificacoes] = useState([]);

  useEffect(() => {
    api
      .post("identificacao/find", {
        id_cliente,
      })
      .then((res) => {
        setIdentificacoes(res.data);
      });
  }, [id_cliente]);

  return (
    <Fragment>
      <h2>Identificação</h2>
      <select onChange={(e) => handleSetVal(e.target.value)}>
        <option value="DEFAULT" disabled selected>
          Selecione uma opção
        </option>
        {identificacoes.map((indetificacao, index) => (
          <option key={index} value={indetificacao.id}>
            {indetificacao.tp_identificacao}
          </option>
        ))}
      </select>
    </Fragment>
  );
}
