import './styles.css';

import * as animationData from '../../assets/lottie/loading1.json'

import Lottie from 'react-lottie';
import React from 'react';

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: animationData,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice'
  }
};

function Loader({display}) {
  return (
    <div>
      {
        display && <div className="loader">
        <Lottie options={defaultOptions}
           height={400}
           width={400}
         />;
         {display}
     </div>
      }
    </div>

  )
}

export default Loader;

