import {
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import React from "react";
import api from "../../services/api";
import { Container, LinkGreen, LinkRed } from "./styles";
import "./styles.css";

interface Action {
  isOpen: boolean;
  handleChangeModalIsOpen: () => void;
  id_atendimento: string;
  id_cliente: string;
  nm_paciente: string;
  id_paciente: string;
}

export function PresencaModal({
  isOpen,
  handleChangeModalIsOpen,
  nm_paciente,
  id_atendimento,
  id_cliente,
  id_paciente,
}: Action) {
  const handleConfirmarPresenca = () => {
    const hr_presenca = new Date().toLocaleTimeString("pt-BR", {
      hour: "numeric",
      minute: "numeric",
    });
    api
      .post("atendimentos/presenca/create", {
        id_cliente,
        id_paciente,
        id_atendimento,
        hr_presenca,
      })
      .then(() => {
        handleChangeModalIsOpen();
      });
  };

  return (
    <Modal transparent isOpen={isOpen}>
      {" "}
      <ModalOverlay />
      <ModalContent>
        <ModalHeader></ModalHeader>
        <ModalBody>
          <h2>
            O paciente {nm_paciente} está com a presença pendente, deseja
            confirmar agora?
          </h2>
          <Container>
            <LinkGreen onClick={() => handleConfirmarPresenca()}>
              Sim, confirmar a presença.
            </LinkGreen>
            <LinkRed onClick={() => handleChangeModalIsOpen()}>
              Não, fechar essa tela
            </LinkRed>
          </Container>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
