import styled from "styled-components";

export const DivLogin = styled.div`
  display: flex;
  flex-direction: column !important;
  justify-content: center !important;
  place-content: center !important;
  align-items: center !important;
  margin: auto;
  max-width: 100% !important;
  align-items: center !important;

  form h1 {
    margin-bottom: 16px;
  }

  form input {
    margin: 0 0 8px !important;
  }

  form button {
    background-color: var(--primary-color0);
    align-items: center !important;
    font-size: 1.2rem;
    height: 4rem !important;
  }
`;
