import styled from "styled-components";

export const Container = styled.header`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  min-width: 100% !important;
  min-height: 4rem;
`;

export const Links = styled.ul`
  margin: 32px;
  list-style: none;
  display: flex;
  justify-content: flex-end;

  &:hover {
    color: var(--gray);
    cursor: pointer;
  }
`;
export const Link = styled.div`
  max-width: 200px;
`;
