import {
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { FiX } from "react-icons/fi";
import { toast } from "react-toastify";
import api from "../../services/api";
import { LinkModal } from "../AtendimentoSelecionadoModal/styles";
import Loader from "../Loader";
import "./styles.css";

interface Action {
  isOpen: boolean;
  handleChangeModalIsOpen: () => void;
  handleReload: () => void;
  info: array;
  id_cliente: string;
}

export function LaudarModal({
  isOpen,
  handleChangeModalIsOpen,
  handleReload,
  info,
  id_cliente,
}: Action) {
  const [loading, setloading] = useState(false);
  const [Resultados, setResultados] = useState([]);
  const [id_resultado, setid_resultado] = useState("");
  const [File, setFile] = useState([]);

  const handleSetVal = (data) => {
    setid_resultado(data);
  };

  async function handleAnexarLaudo(e) {
    window.scrollTo(0, 0);
    setloading(true);
    e.preventDefault();

    const formData = new FormData();
    formData.append("image", File);
    formData.append("id_atendimento", info.id);
    formData.append("id_cliente", id_cliente);
    formData.append("id_resultado", id_resultado);

    api
      .post("laudos/laudar", formData, {})
      .then(() => {
        handleReload();
        handleChangeModalIsOpen();
      })
      .catch(() => {
        toast.error("Erro ao anexar");
      });
  }

  useEffect(() => {
    if (isOpen) {
      api
        .post("resultados", {
          id_cliente,
          sn_ativo: "S",
        })
        .then((res) => {
          setloading(false);
          setResultados(res.data);
        });
    }
  }, [isOpen, info]);

  return (
    <Modal
      transparent
      onRequestClose={handleChangeModalIsOpen}
      isOpen={isOpen}
      style={{
        overlay: {},
        content: {
          background: "#f0f2f5",
          borderRadius: 8,
          position: "fixed",
          left: "10%",
          border: 2,
          width: "80%",
          height: "80%",
          alignContent: "center",
        },
      }}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader></ModalHeader>
        <ModalBody>
          <Loader display={loading} />
          <LinkModal>
            <FiX
              onClick={() => handleChangeModalIsOpen()}
              color="red"
              size="32"
            />
          </LinkModal>
          <p>Laudar </p>

          <select onChange={(e) => handleSetVal(e.target.value)}>
            <option value="DEFAULT" disabled selected>
              Selecione uma opção
            </option>
            {Resultados.map((resultado, index) => (
              <option key={index} value={resultado.id}>
                {resultado.desc_tipo_resultado}
              </option>
            ))}
          </select>

          {id_resultado ? (
            <form
              onSubmit={handleAnexarLaudo}
              encType="multipart/form-data"
              id="form-laudar"
            >
              <input
                id="selecao-arquivo"
                type="file"
                name="arquivo"
                title="Anexar Laudo"
                placeholder="Anexar Laudo"
                required=""
                onChange={(e) => setFile(e.target.files[0])}
              />

              <button type="submit" id="button-laudar">
                {loading ? "AGUARDE" : "ANEXAR LAUDO"}
              </button>
            </form>
          ) : (
            ""
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
