import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 32px;
  padding-right: 32px;
  background-color: var(--background);
  min-height: 100vh;
  min-width: 100%;
  overflow: hidden;

  a {
    margin: 8px;
  }
  a:hover {
    color: var(--blue);
  }

  h1,
  h2,
  h3,
  p,
  small {
    color: var(--text-body) !important;
  }

  h1 {
    font-size: 37.89px;
  }

  h2 {
    font-size: 28.43px;
  }

  h3 {
    font-size: 21.32px;
  }

  p {
    font-size: 16px;
  }

  small {
    font-size: 12px;
  }

  form {
    text-align: center;
    align-items: left;
    width: 100%;
    width: 100%;
    display: flex;
    display: flex;
    flex-direction: column;
  }

  form input {
    padding: 16px;
    height: 3rem;
    width: 100%;
    margin-bottom: 8px;
    border: none;
    border-radius: 4px;
    color: var(--text-body);

    &::placeholder {
      color: var(--text-body);
    }
  }

  form button {
    width: 100% !important;
    height: 3rem;
    border: none;
    border-radius: 4px;
    background-color: var(--primary-color0);
    color: var(--white);
  }

  ul {
    list-style: none;
  }
  ul li {
    margin-top: 2rem;
  }

  input,
  select,
  option {
    padding: 16px;
    height: 4rem;
    margin: 8px;
    border: none;
    border-radius: 4px;
    color: var(--text-body);

    &::placeholder {
      color: var(--text-body);
    }
  }
`;
