import { Container } from "./styles";
import React from "react";

interface Action {
  title: string;
  action: () => void;
  type: string;
}

export function StyleInput({ title, action, type }: Action) {
  return (
    <Container
      placeholder={title}
      onChange={(e) => action(e.target.value)}
      type={type}
    />
  );
}
