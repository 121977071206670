/*eslint-disable eqeqeq*/
import {
  Button,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";
import React from "react";
import { toast } from "react-toastify";
import api from "../../services/api";
import { ModalSmallRed } from "../../styles/modal.css";
import { Container } from "./styles";

interface Action {
  isOpen: boolean;
  handleChangeModalIsOpen: () => void;
  info: array;
}

export function AtendimentoRefazerExames({
  isOpen,
  handleChangeModalIsOpen,
  info,
}: Action) {
  const handleSendEmail = () => {
    const titulo = `REFAZER - ${info.nm_paciente} - Atendimento: ${info.cd_atendimento}`;
    const mensagem = `O usuário ${localStorage.getItem(
      "usuario"
    )} está solicitando que o paciente ${info.nm_paciente} refaça o exame ${
      info.nm_exame
    } \n Atendimento: ${info.cd_atendimento} \n Pedido: ${
      info.cd_pedido
    } \n Telefone de Contato ${info.nr_celular}`;
    api
      .post("mail/polissonografia/refazendo", {
        titulo,
        mensagem,
      })
      .then(() => {
        toast.success("Email enviado com sucesso...", {
          position: "top-center",
          autoClose: 2000,
        });
        handleChangeModalIsOpen();
      });
  };

  return (
    <Modal
      transparent
      isOpen={isOpen}
      style={ModalSmallRed}
      size="lg"
      onClose={() => handleChangeModalIsOpen()}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalHeader></ModalHeader>
        <ModalBody>
          <Container>
            <Text>
              {"Você tem certeza que deseja enviar o email para refazer o exame do paciente " +
                info.nm_paciente +
                "?"}
            </Text>

            <HStack py={4} justifyContent={"space-around"} w="full">
              <Button onClick={() => handleSendEmail()} colorScheme="red" p={4}>
                Sim, enviar email!
              </Button>

              <Button
                onClick={() => handleChangeModalIsOpen()}
                colorScheme="green"
                p={4}
              >
                Não, cancelar envio!
              </Button>
            </HStack>
          </Container>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
