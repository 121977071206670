import "./global.css";
import "react-toastify/dist/ReactToastify.min.css";

import { ChakraProvider, extendTheme } from '@chakra-ui/react';
import React, { Fragment } from "react";

import Main from "./styles/Main";
import Routes from "./routes";
import { ToastContainer } from "react-toastify";
import { themePerson } from "./styles/theme";

const mytheme = extendTheme(themePerson);

function App() {
  return (
    <ChakraProvider resetCSS theme={mytheme}>
      <Main>
        <Fragment>
          <ToastContainer />
          <Routes />
        </Fragment>
      </Main>
    </ChakraProvider>
  );
}

export default App;
